import React, { useEffect, useState } from "react"
import "./css/style.scss"
import * as classes from "./css/main_header.module.css"
import logo from "./static/MT_logo.png"
import logo_webp from "./static/MT_logo.webp"
import arrow from "./static/service_img.webp"
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap"
import { Link } from "gatsby"

const MainHeader = () => {
  const [show, setShow] = useState(false)

  return (
    <div class="header-main">
      <Navbar bg="light" variant="dark" expand="lg" id="navbar-fixed">
        <Container>
          <Navbar.Brand href="/">
            <picture>
              <source srcSet={logo_webp} type="image/webp" />
              <source srcSet={logo} type="image/png" />
              <img srcSet={logo_webp} alt="title" className="logo-styling" />
            </picture>
          </Navbar.Brand>
          <Navbar.Toggle className="toggle-styling" />
          <Navbar.Collapse id="basic-navbar-nav" onClick={() => setShow(!show)}>
            <Nav>
              {/* <Link to="/about"  > */}
              <Nav.Link href="/about" className="navLink">
                About Us
              </Nav.Link>
              {/* </Link>  */}
              <NavDropdown
                className="navLink"
                title="Services"
                id="basic-nav-dropdown"
              >
                <section className={classes.service_section}>
                  <div className={classes.service_div}>
                    <img
                      src={arrow}
                      alt="Smiley face"
                      width="50"
                      height="50"
                      className={classes.service_img}
                    />
                    <Link
                      to="/services/corporate-management-and-training"
                      className={classes.service_div_Text}
                    >
                      Corporate Management and Training
                    </Link>
                  </div>
                  <div className={classes.service_div}>
                    <img
                      src={arrow}
                      alt="Smiley face"
                      width="50"
                      height="50"
                      className={classes.service_img}
                    />
                    <Link
                      to="/services/learning-and-development-consultation"
                      className={classes.service_div_Text}
                    >
                      Learning and Development Consultation
                    </Link>
                  </div>
                  <div className={classes.service_div}>
                    <img
                      src={arrow}
                      alt="Smiley face"
                      width="50"
                      height="50"
                      className={classes.service_img}
                    />
                    <Link
                      to="/services/program-and-standard-based-training"
                      className={classes.service_div_Text}
                    >
                      Program and Standard Based Training
                    </Link>
                  </div>
                </section>

                {/* <NavDropdown.Item className="navLink" href="#action/3.2">
                  {`Service 2`}
                </NavDropdown.Item>
                <NavDropdown.Item className="navLink" href="#action/3.3">
                  {`Service 3`}
                </NavDropdown.Item> */}
              </NavDropdown>
              <Nav.Link className="navLink" href="/#our_trainers">
                Our Trainers
              </Nav.Link>
              <Nav.Link className="navLink" href="/become-a-trainer">
                Become a Trainer
              </Nav.Link>
              <Nav.Link
                className="navLink"
                href="https://mastrainers.com/tna-bot/"
              >
                TNA bot
              </Nav.Link>
              <Nav.Link className="navLink" href="/contact-us">
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}
export default MainHeader
