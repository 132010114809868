import React from "react"
import * as classes from "./main_footer.module.scss"
import { GoLocation } from "react-icons/go"
import { BiPhone } from "react-icons/bi"
import { HiOutlineMail } from "react-icons/hi"

import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa"

import loadable from "@loadable/component"
const CalendlyForm = loadable(() => import("../calendly_form/calendly_form"), {
  fallback: <div></div>,
})
const FooterLogo = loadable(() => import("./footer_logo"), {
  fallback: <div></div>,
})
export default function MainFooter() {
  const [openForm, setopenForm] = React.useState(false)

  return (
    <div className={classes.main_div}>
      <div id="banner1">
        <CalendlyForm
          closeForm={() => setopenForm(false)}
          openForm={openForm}
        />
      </div>
      <div className={classes.address_div}>
        <h2>Get In Touch</h2>

        <div className={classes.icon_main}>
          <GoLocation className={classes.icon_styling} />
          <p>
            IFZA Business Park Building A2 - Dubai Silicon Oasis - Dubai -
            United Arab Emirates.
          </p>
        </div>
        <div className={classes.icon_main}>
          <BiPhone className={classes.icon_styling} />
          <p>+971545485390</p>
        </div>
        <div className={classes.icon_main}>
          <HiOutlineMail className={classes.icon_styling} />
          <p className={classes.mail_text}>Contact@mastrainers.com</p>
        </div>
        <div className={classes.icons_div}>
          <a href="https://www.facebook.com/mastertrainersfzco">
            <FaFacebookF className={classes.social_icon_styling} />
          </a>
          <a href="https://www.instagram.com/mastrainers/">
            <FaInstagram className={classes.social_icon_styling} />
          </a>
          <a href="https://twitter.com/mas_trainers">
            <FaTwitter className={classes.social_icon_styling} />
          </a>
          <a href="https://api.whatsapp.com/send?phone=971545485390">
            <FaWhatsapp className={classes.social_icon_styling} />
          </a>
          <a href="https://www.linkedin.com/company/12648270/admin/">
            <FaLinkedinIn className={classes.social_icon_styling} />
          </a>
        </div>
      </div>
      <FooterLogo onClick={() => setopenForm(true)} />
    </div>
  )
}
