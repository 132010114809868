// extracted by mini-css-extract-plugin
export var main_div = "main_footer-module--main_div--TAP36";
export var form_div = "main_footer-module--form_div--4SRgQ";
export var logo_styling = "main_footer-module--logo_styling--Pi9oN";
export var submit_button_styling = "main_footer-module--submit_button_styling--w+gJn";
export var address_div = "main_footer-module--address_div--4NRC2";
export var icon_main = "main_footer-module--icon_main--WfjQS";
export var icon_styling = "main_footer-module--icon_styling--mA9y9";
export var mail_text = "main_footer-module--mail_text--cyRow";
export var icons_div = "main_footer-module--icons_div--1st7r";
export var social_icon_styling = "main_footer-module--social_icon_styling--mAFNz";